import React, { useEffect, useMemo, useRef, useState }  from "react";
import { useNavigate } from 'react-router-dom';
import { getResource } from "../../config";
import {getLangFromCookie} from "../header/Header";

type Props = {
    img: string
    id: string
    title: string
    width?: number
    to?: string
    imgPosition?: string
    index?: number
    type?: string
    new?: boolean
}

export default function({img, id, title, width, imgPosition, index, type, new: isNew}: Props) {
    const navigate = useNavigate()
    const [stateOpacity, setStateOpacity] = useState(0)
    const interval = useRef<ReturnType<typeof setInterval>>()
    const opacity = useRef(0)
    const language = useMemo(() => getLangFromCookie(),[])

    useEffect(() => {
        setTimeout(() => {
            interval.current = setInterval(() => {
                if (opacity.current < 1) {
                    opacity.current += 0.2
                    setStateOpacity(opacity.current)
                } else {
                    interval.current && clearInterval(interval.current)
                }
            }, 50)

        }, 50 * (index || 1))
    }, [])

    return (
        <div
            onClick={() => id && navigate(`/${type}/${id}`)}
            id={id} className="reciepe-card"
            style={{
                opacity: stateOpacity,
                backgroundImage: `url(${getResource(img)})`,
                width: width+'%',
                ...imgPosition && {backgroundPosition: imgPosition}
            }}>
            <h3>
                {title}
            </h3>
            {isNew &&
                <span className="reciepe-card__new">
                    {language == 'ru' ? 'новинка' : 'new'}
                </span>
            }
            {/* {admin && <i onClick={handleDeleteReciepe} className="fa fa-close"/>} */}
        </div>
    )
}
import React, { useEffect, useState } from "react";
import Curtain from "../components/reusable/Curtain";
import Loader from "../components/reusable/Loader";
import Video from "../components/reusable/Video";
import SliceListItem from "../components/SliceListItem";
import { BACKEND, getResource } from "../config";
import useNaviScroll from "../hooks/naviScroll.hook";
import { request } from "../services/http";
import { Curtain as CurtainT } from "../types/controlTypes";

type Product = {
    photo: string
    title: string
}

export type Retail = {
    curtain: CurtainT
    products: Product[]
    benefits: string[]
    fast_and_cook: {title: string, photo: string}[]
    where_to_buy: {title: string, place: string, to: string}[]
    texts: string[]
}

export default function Retail() {
    const [data, setData] = useState<Retail>()
    const {go} = useNaviScroll()


    useEffect(
        () => {
            request(`${BACKEND}/api/database/retail_products`, 'GET')
                .then((data) => {
                   setData(data)
                })
                .catch(({message}) => console.log(message))
        },
        []
    )

    const {curtain, texts} = data || {texts: new Array(10).fill('')}
    const {title, description, img} = curtain || {}
    return (
        <>
            {title && img && <Curtain
                hideButton
                title={title}
                description={description}
                imgUrl={getResource(img)}
            />}
            <div className="retail-products">
                {data?.products.map(({photo, title}) =>
                    <div key={title} className='retail-products__item'>
                        <div className='retail-products__item__photo' style={{backgroundImage: `url(${getResource(photo)})`}}/>
                        <h3>{title}</h3>
                    </div>
                )}
            </div>
            <h1 className="t_terrakot mt_big retail-products__header">
                {texts[0]}
            </h1>
            <ul className="slice-list retail-products__benefits">
                {data?.benefits.map((benefit, index) => <SliceListItem key={benefit} text={benefit} index={index}/>)}
            </ul>
            <h1 className="t_terrakot mt_big retail-products__header">
                {texts[1]}
            </h1>
            <div className="retail-products__white-box">
                <h1 className="t_grafit mt_mid retail-products__header">
                    {texts[2]}
                </h1>
                <div className="retail-products__fast-and-cook">
                    {data?.fast_and_cook.map(({title, photo}) =>
                        <div key={title} className="retail-products__fast-and-cook__item">
                            <img src={getResource(photo)}/>
                            <span>{title}</span>
                            <div className="retail-products__fast-and-cook__divider"/>
                        </div>
                    )}
                </div>
            </div>
            <Video id='presentation' className="retail-products__video" url={getResource('video_retail.mp4')}/>
            <div className="retail-products__burger">
                <img src={getResource('burger.png')}/>
                <div className="retail-products__burger">
                    <h1>{texts[3]}</h1>
                    <p>{texts[4]}</p>
                    <p>{texts[5]}</p>
                    <button onClick={() => go('/solutions')}>{texts[6]}</button>
                </div>
            </div>
            <div className="retail-products__mockup">
                <div>
                    <h1>{texts[7]}</h1>
                    <p>{texts[8]}</p>
                </div>
                <img src={getResource('mockup.png')}/>
            </div>
            <div className="retail-products__where-to-buy">
                <h1 className="t_terrakot mt_mid retail-products__header">{texts[9]}</h1>
                <div className="retail-products__where-to-buy__list">
                    {data?.where_to_buy.map(({title, place, to}, index) =>
                        <span key={title + index} className="retail-products__where-to-buy__list__item">
                            <SliceListItem text={title} index={index} direction="sixColumn"/>
                            <span>{place}</span>
                        </span>
                    )}
                </div>
            </div>
        </>
    )
}
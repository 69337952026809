import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonType } from '../../types/controlTypes';
import { BACKEND, getResource } from './../../config';
import HeaderButton from './HeaderButton';
import { request } from '../../services/http';
import { getDevice, useDevice } from '../../hooks/device.hook';
import { useOnScroll } from '../../hooks/onScroll.hook';

type Lang = 'ru' | 'eng'

export const getLangFromCookie = (): Lang =>
	document.cookie
		.split(';')
		.find(cookie => cookie.includes('language'))
		?.split('=')[1] as Lang || 'ru';

export default function Header() {
	const { width } = useDevice();
	const [scrolled, setScroll] = useState(false);
	const [language, setLanguage] = useState<string>();
	const [openedToolbar, setOpenedToolbar] = useState(false);
	const location = useLocation();
	const scroll = useOnScroll();

	const handleLangChange = (lang: Lang) => {
		setLanguage(lang)
		document.querySelector('html')?.setAttribute('lang', lang)
	}

	useEffect(() => setOpenedToolbar(false), [location]);

	useEffect(() => handleLangChange(getLangFromCookie()), []);

	const toggleLang = (lang: Lang) => {
		request(
			`${BACKEND}/api/language/${lang}`,
			'GET',
			undefined,
			undefined,
			undefined,
			true
		)
			.then(({ language }) => {
				setLanguage(language);
				window.location?.reload();
			})
			.catch(console.log);
	};

	const [
		{
			ourProduction,
			readySolutions,
			aboutCompany,
			aboutUs,
			fieldToDish,
			career,
			contacts,
		},
		setData,
	] = useState<{ [key: string]: string }>({});

	useEffect(() => {
		request(`${BACKEND}/api/database/headerfooter`, 'GET')
			.then(({ HEADER }) => {
				setData(HEADER);
			})
			.catch(({ message }) => console.log(message));
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', listenToScroll);
		return () => window.removeEventListener('scroll', listenToScroll);
	}, []);

	function listenToScroll() {
		document.body.scrollTop || document.documentElement.scrollTop === 0
			? setScroll(false)
			: setScroll(true);
	}

	const navigate = useNavigate();
	const buttons: Array<ButtonType> = [
		{
			title: ourProduction,
			dropdownItems: [
				{ title: 'Foodservice', to: 'foodservice' },
				{ title: 'Retail', to: 'retail' },
			],
		},
		{ title: readySolutions, to: 'solutions' },
		// {title: 'Устойчивое развитие', to: 'development'},
		{
			title: aboutCompany,
			dropdownItems: [
				{ title: aboutUs, to: 'about' },
				{ title: fieldToDish, to: 'field_to_dish' },
			],
		},
		{ title: career, to: 'career' },
		{ title: contacts, to: 'contacts' },
	];

	return getDevice(width) == 'desktop' ? (
		<div
			className={`header ${scrolled ? 'scrolled' : ''}`}
			style={{ top: !scrolled ? 0 : -50 + 'px' }}
		>
			<div className='header-row reverse'>
				{/* <SearchInput/> */}
				<a className='t_grafit' href='tel:+7 (4742) 723-000'>
					+7 (4742) 723-000
				</a>
				<select
					className='header-select'
					onChange={({ target }) => toggleLang(target.value as 'eng' | 'ru')}
					value={language}
				>
					<option value={'eng'}>EN</option>
					<option value={'ru'}>RU</option>
				</select>
				{/* <i className="fa fa-map-marker" aria-hidden="true"></i> */}
				{/* <i className="fa fa-facebook"></i>
                <i className="fa fa-instagram"></i> */}
				{/* <i onClick={() => {
                    dispatch({type: "LOGOUT"})
                    navigate('/')
                }} className="fa fa-sign-in"></i> */}
			</div>
			<div className='header-row'>
				<div
					className={`header-logo ${scrolled ? 'header-logo_scrolled' : ''}`}
					onClick={() => navigate('/')}
				>
					<img src={getResource('logo.svg')} />
				</div>
				{buttons.map((button, index) => (
					<HeaderButton
						key={index}
						title={button.title}
						to={button.to}
						dropdownItems={button.dropdownItems}
					/>
				))}
			</div>
		</div>
	) : (
		<>
			<div className={`header ${openedToolbar ? 'header_opened' : ''}`}>
				<div className='header-row'>

					{buttons
						.flatMap(button =>
							button.dropdownItems ? button.dropdownItems : button
						)
						.map((button, index) => (
							<HeaderButton
								key={index}
								title={button.title}
								to={button.to}
								dropdownItems={button.dropdownItems}
							/>
						))}
					<br></br>
					<span>
						<a className='t_grafit' href='tel:+7 (4742) 723-000'>
							+7 (4742) 723-000
						</a>
					</span>
					<select
						className='header-select'
						onChange={({ target }) => toggleLang(target.value as 'eng' | 'ru')}
						value={language}
					>
						<option value='eng'>EN</option>
						<option value='ru'>RU</option>
					</select>
				</div>
			</div>
			<span
				// className={`header-toggle-button ${
				// 	(justInited || scroll || openedToolbar || location.pathname == '/foodservice') &&
				// 	'header-toggle-button_active'
				// } ${
				// 	firstTimeInited && !openedToolbar &&
				// 	'header-toggle-button_bouncing'
				// }`}
                className='mobile-header'
				onClick={() => setOpenedToolbar(opened => !opened)}
			>
				<div className='mobile-header__header-logo' onClick={() => navigate('/')}>
					<img src={getResource('logo.svg')} />
				</div>
				<i className='fa fa-bars'></i>
			</span>
		</>
	);
}

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const scrollTime = 500

export default function useNaviScroll () {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const go = (to?: string) => {
        if (to) {
            dispatch({type: 'TOGGLE_BLUR', payload: true})
            window.scrollTo({top: 0, behavior: 'smooth'})
            setTimeout(() => {
                navigate(to)
                dispatch({type: 'TOGGLE_BLUR', payload: false})
            }, scrollTime)
        }
    }

    return {go}
}
import React from "react"; 

type Props = {
    children: React.ReactChild
    icon: string
}

export default function({children, icon}: Props) {
    return <div className="icon">
        <span className={icon}/>
        {children}
    </div>
} 
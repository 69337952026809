import React, { useMemo, useRef, useState } from "react";
import { getResource } from "../../config";
import {useDevice} from "../../hooks/device.hook";
import Video from "../reusable/Video";
import StorySliderItem from "./StorySliderItem";

const slideWidth = 137
const slidesWidth = 600
export type StoryItem = {img: string, text: string, media: string, title: string}

export default function ({items}: {items: StoryItem[]}) {
    const [slide, setSlide] = useState<number>(1)
    const [hovered, setHovered] = useState<number>()
    const {device} = useDevice()
    const slidesRef = useRef<HTMLDivElement>(null)

    const slides = useMemo(
        () => items.map((_, index, arr) =>  -(slideWidth * arr.length) + slidesWidth + ((index + 1) * slideWidth)+'px'),
        [items, window.innerWidth, slidesRef.current]
    )


    function slideButtonHandler(direction: 'left' | 'right') {
        direction === 'right'
            ? setSlide(prev => prev - 1 > 0 ? prev-1 : prev)
            : setSlide(prev => prev < items.length ? prev + 1 : prev)
    }

    const isMobile = device !== 'desktop'

    return <>
        <div className="story-slider">
            <div
                ref={slidesRef}
                className="story-slider__slides"
                style={{...!isMobile && {left: slides[slide-1]}}}
            >
                {items.map(({title, img, media, text}, index) => <StorySliderItem
                    key={title+img}
                    title={title}
                    media={media}
                    text={text}
                    img={img}
                    choosen={items.length - index === hovered || items.length - index === slide}
                    onClick={() => setSlide(items.length - index)}
                    onHover={(exit) => exit ? setHovered(items.length + 100) : setHovered(items.length - index)}
                />)}
            </div>
            {!isMobile &&
                <div className="story-slider__buttons">
                    <div onClick={()=>slideButtonHandler('left')}><span className="material-icons">chevron_left</span></div>
                    <div onClick={()=>slideButtonHandler('right')}><span className="material-icons">chevron_right</span></div>
                </div>
            }
        </div>
        <div className="story-slider__content">
            <p>
                <span>{items[items.length - slide]?.title || ''}</span>
                {items[items.length - slide]?.text.split('|')[0] || ''}
            </p>
            {items[items.length - slide]?.text.split('|')[1]
                ? <p>{items[items.length - slide]?.text.split('|')[1]}</p>
                : ''}
            {items[items.length - slide]?.media !== ''
                ? <Video
                    url={getResource(items[items.length - slide].media)}
                    id='story'
                />
                : null
            }
        </div>
    </>
}
import React, { useCallback, useEffect, useState } from "react"
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { BACKEND } from "../../config";
import { request } from "../../services/http";
import ToggleCheckbox from "../reusable/ToggleCheckbox"
import ToggleSwitch from "../reusable/ToggleSwitch"

type State = Record<string, string[]>

export default function () {
    const navigate = useNavigate();
    const [{categories, types, solutions, filterTitles}, setState] = useState<State>({categories: [], types: [], solutions: [], filterTitles: []})

    useEffect(
        () => {
            request(`${BACKEND}/api/database/foodservice_categories`, 'GET')
                .then((data) => setState(data))
                .catch(({message}) => console.log(message))
        },
        []
    )

    return <div className='foodservice-filters'>
        <div className="foodservice-filters__reset-filters">
            <h2 className="t_grafit">{filterTitles[0]}</h2>
            <div className="foodservice-filters__reset-filters__button" onClick={(() => navigate({search: ''}))}>{filterTitles[1]}</div>
        </div>
        <li className="foodservice-filters-items__item">
                    {filterTitles[5]}
                    <ToggleFilter param={'new'} filter={'true'}/>
                </li>
        <h2 className="t_grafit">{filterTitles[2]}</h2>
            {categories.map(category =>
                <li key={category} className="foodservice-filters-items__item">
                    {category}
                    <ToggleFilter key={category} param={'category'} filter={category}/>
                </li>
            )}
        <h2 className="t_grafit">{filterTitles[3]}</h2>
        <ul className="foodservice-filters-items">
            {types.map(type =>
                <li key={type} className="foodservice-filters-items__item">
                    {type}
                    <ToggleFilter key={type} param={'type'} filter={type}/>
                </li>
            )}
        </ul>
        <h2 className="t_grafit">{filterTitles[4]}</h2>
        <ul className="foodservice-filters-items">
            {solutions
                .filter(solution => solution != 'новинка' )
                .map(solution => (
                    <li key={solution} className="foodservice-filters-items__item">
                        {solution}
                        <ToggleFilter key={solution} param={'solution'} filter={solution}/>
                    </li>
                ))
            }
        </ul>
        <br/>
    </div>
}

// function CategoryItem({category}: {category: {[key: string]: string[]}}) {
//     const categoryName = Object.keys(category)[0]

//     return <div className="category-item">
//         <h2 className="t_grafit">{tr(categoryName)}</h2>
//         <ul>
//             {category[categoryName].map(field => (
//                 <li key={field+1}>
//                     <ToggleFilter filter={field} check/>
//                 </li>
//             ))}
//         </ul>
//     </div>
// }

function ToggleFilter({filter, check, param}: {filter: string, check?: boolean, param: string}) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate();

    function handleChange() {
        let newParams
        if (searchParams.getAll(param).includes(filter))
            newParams = new URLSearchParams([...searchParams.entries()].filter(([key, value]) => !(key == param && value == filter)))
        else {
            searchParams.append(param, filter)
            newParams = searchParams
        }
        navigate({search: createSearchParams(newParams).toString()}, {replace: true})
    }

    return (
        check
            ? <ToggleCheckbox onChange={handleChange} value={!!searchParams.has(filter)} text={filter}/>
            : <ToggleSwitch onChange={handleChange} value={searchParams.getAll(param).includes(filter)}/>
    )
}
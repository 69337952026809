import React from "react";
import { getResource } from "../config";

type Props = string[][]

export default function({cards}: {cards: Props}) {
    return (
        <div className="career-cards">
            <div className="career-cards__cards">
                {cards.map(([url, text]) =>
                    <div key={url + text} className="career-cards__card">
                        <img src={getResource(url)}/>
                        <div>{text}</div>
                    </div>
                    )
                }
            </div>
        </div>
    )
}
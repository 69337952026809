import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BACKEND, getResource } from './../config';
import { getReciepes } from '../services/reciepe';
import { TinitialState } from '../store';
import Curtain from '../components/reusable/Curtain';
import Reciepe from '../components/reciepes/Reciepe';
import { ReciepeData, ReciepeSchema } from '../components/reciepes/Reciepes';
import useNaviScroll from '../hooks/naviScroll.hook';
import { request } from '../services/http';

export default function () {
	const [reciepes, setReciepes] = useState<ReciepeSchema[]>();
	const [data, setTexts] = useState<ReciepeData>()
	const loadReciepes = () => getReciepes();
	const { go } = useNaviScroll();

	useEffect(() => {
		request(`${BACKEND}/api/database/solutions_texts`, 'GET')
			.then(data => setTexts(data))
			.catch(({ message }) => console.log(message));
	}, []);

	useEffect(() => {
		loadReciepes().then(data => data && setReciepes(data.items));
	}, []);

	if (!reciepes || !data)
		return null

	const {curtain, texts} = data

	return (
		<>
			<Curtain
				title={curtain.title}
				imgUrl={getResource(curtain.description)}
			/>
			<div className='reciepes'>
				{reciepes &&
					reciepes.length > 0 &&
					reciepes.map(({ photo, id, title }, index) => {
						return (
							<Reciepe
								key={index + id}
								img={photo}
								id={id}
								title={title}
								width={31}
								type='reciepes'
							/>
						);
					})}
			</div>
			<div className='reciepes-details__bottom-buttons'>
				<div onClick={() => go(texts[3][0])}>
					<h3>{texts[3][1]}</h3>
					<div>
						<p>{texts[3][2]}</p>
						<span className='material-icons'>chevron_right</span>
					</div>
				</div>
				<div onClick={() => go(texts[4][0])}>
					<h3>{texts[4][1]}</h3>
					<div>
						<p>{texts[4][2]}</p>
						<span className='material-icons'>chevron_right</span>
					</div>
				</div>
			</div>
		</>
	);
}

import React, { useRef} from "react";
import SimpleSliderItem from "./SimpleSliderItem";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation} from "swiper";

type Items = {items: string[]}

export default function ({items}: Items) {

    return (
        <div className="simple-slider-container">
            <div className="simple-slider">
                <div className="about-slide-prev"><span className="material-icons">chevron_left</span></div>
                <div className="about-slide-next"><span className="material-icons">chevron_right</span></div>
                <Swiper 
                    navigation={{
                        nextEl: '.about-slide-next', 
                        prevEl: '.about-slide-prev'
                    }} 
                    loop
                    modules={[Navigation]} 
                    className="mySwiper"

                >
                    {items.map((img, index)=>
                        <SwiperSlide key={index + 'simpleSlider'}>
                            <SimpleSliderItem img={img} key={img}/>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    )
}
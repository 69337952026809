import React from "react";
import {usePopup} from "../../hooks/popup.hook"

export function Popup() {
    const {popup} = usePopup()

    return popup
        ? <div className="popup">
            <div className="popup__content">
                <span>
                {popup}
                </span>
            </div>
        </div>
        : null
}

export async function request(
    url: string,
    method: 'GET' | 'POST',
    body?: object | null | string | string[],
    headers = {} as Record<string, string>,
    formData?: boolean,
    credentials?: boolean
) {
    try {
      if (body) {
        if (formData) {
          const formData = new FormData()
          Object.entries(body).forEach(([key, value]) => formData.append(key, value))
          body = formData as unknown as string
        } else {
          body = JSON.stringify(body)
          headers['Content-Type'] = 'application/json'
        }
      }
      const response = await fetch(url, {
        method,
        body,
        headers,
        credentials: 'include'
      })
      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Что-то пошло не так')
      }

      return data
    } catch (e) {
      throw e
    }
}
import React, { useRef, useState, useEffect } from "react"
import {useLocation} from "react-router-dom"
import {usePopup} from "../../hooks/popup.hook"

type Props = {
    url: string,
    id?: string
    className?: string,
    width?: string,
    height?: string,
    poster?: string
}


export default function ({url, className, width, height, poster, id}: Props) {
    const [play, setPlay] = useState(true)
    const link = `${window.location.href.split('#')[0]}#${id}`

    const {togglePopup} = usePopup()
    // const videoRef = useRef<HTMLVideoElement>(null)

    // useEffect(() => {
    //     if (!play) videoRef?.current?.play()
    //     else videoRef?.current?.pause()
    // }, [play])

    return <div className={`video ${className ? className : ''}`}>
        <video
            id={id}
            // ref={videoRef}
            style={{width, height}} controls poster={poster || url.split('.')[0] + '.png'}
        >
            <source src={url}/>
        </video>
        {/* <div
            className='video-controls'
            onClick={() => setPlay(play => !play)}
        >
            <span className={`video-controls__button fa fa-play ${[play ? 'video-controls__button_active' : '']}`}/>
        </div> */}
        {id &&
            <span
                className="video-overlay"
            >
                <span className="video-overlay__share"
                    onClick={() => {
                        togglePopup(`Ссылка ${link} скопирована`)
                        navigator.clipboard.writeText(link)
                    }}
                >
                    <i className="fa fa-share"/>
                </span>
            </span>
        }
    </div>
}
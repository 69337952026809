import React, { Fragment, useEffect, useRef, useState, WheelEvent} from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation} from "swiper";
import { getResource } from "../config";
import { useWindowSize } from "../hooks/width.hook";

export type CareerSliderItemType = {
    head: string
    text: string | string[]
    img: string | null
}

type Props = {
    items: (CareerSliderItemType | CareerSliderItemType[])[]
    we: string
}

export default function ({items, we}: Props) {
    const {width} = useWindowSize()
    const container = useRef<HTMLDivElement>(null)
    const next = useRef<HTMLDivElement>(null)
    const prev = useRef(null)
    const throttle = useRef(false)
    const swiperRef = useRef<any>(null)

    useEffect(() => {
        container.current?.addEventListener('wheel', handleWheel, {passive: false})
        return () => container.current?.removeEventListener('wheel', handleWheel)
    }, [])

    function handleWheel(event: any) {
        const isLast = swiperRef.current?.querySelector('.swiper-wrapper')?.lastChild.className.includes('active')
        const direction = event.deltaY > 0 ? 'next' : 'prev'
        if (direction == 'next' && isLast) {}
        else {
            event.preventDefault()
            if (!throttle.current) {
                (event.deltaY > 0 ? next.current : prev.current)?.click()
                throttle.current = true
                setTimeout(() => throttle.current = false, 200)
            }
        }
    }

    return (
        <div ref={container} className="career-slider-container">
            <div className="career-slider">
                <Swiper
                    // @ts-ignore: 2322
                    ref={swiperRef}
                    centeredSlides
                    spaceBetween={600}
                    slidesPerView={width && width > 1200 ? 2 : 1}
                    navigation={{
                        nextEl: '.about-slide-next',
                        prevEl: '.about-slide-prev'
                    }}
                    modules={[Navigation]}
                    className="mySwiper"
                    rewind

                >
                    {items.map((item, index)=>
                        <SwiperSlide key={index}>
                            <CareerSliderItem key={index + 1115953} items={item} we={we}/>
                        </SwiperSlide>
                    )}
                </Swiper>
                <div ref={next} className='about-slide-next'></div>
                <div ref={prev} className='about-slide-prev'></div>
            </div>
        </div>
    )
}

function CareerSliderItem({
    items,
    nextItem,
    multi,
    we
}: {
    items: CareerSliderItemType | CareerSliderItemType[]
    nextItem?: CareerSliderItemType
    multi?: boolean
    we?: string
}) {
    const [parsedItems, setParsedItems] = useState<CareerSliderItemType[]>()
    const [choosenSlide, setChoosenSlide] = useState(0)
    const [unactive, setUnactive] = useState(false)
    const [nextItemState, setNextItemState] = useState<CareerSliderItemType>()
    const [nextItemHead, setNextItemHead] = useState('')

    const timer = useRef<null | NodeJS.Timer>(null)

    function changeChoosenSlide() {
        if (Array.isArray(items)) {
            setUnactive(true)
            setTimeout(() => {
                setChoosenSlide(choosenSlide => choosenSlide < items.length - 1 ? choosenSlide + 1 : 0)
                setUnactive(false)
            }, 500)
        }
    }

    useEffect(() => {
        setNextItemHead(nextItem?.head || '')
        setTimeout(() => setNextItemState(nextItem), 500)
    }, [items])

    useEffect(() => {
        timer.current = setInterval(changeChoosenSlide, 3000 + 300 * Math.floor(Math.random() * 10))
        return () => clearInterval(timer.current as NodeJS.Timer)
    }, [])

    useEffect(() => {
        if (Array.isArray(items)) setParsedItems(items)
    }, [])

    if (!Array.isArray(items)) {
        const {img, text, head} = items
        return (
            <div className={`career-slider-item ${multi ? 'career-slider-item_multi' : ''}`}>
                {img && <>
                    <img className="career-slider-item__img" src={getResource(img)}/>
                    {nextItemState?.img && <img className="career-slider-item__img_plus" src={getResource(nextItemState?.img)}/>}

                    <div>
                        <div className="career-slider-item__head">
                            {we}
                            <img className="career-slider-item__heart" src={getResource('career_card_6.svg')}/>
                            <span className={`career-slider-item__tail${head != nextItemHead ? '_animate' : ''}`}>{head}</span>
                        </div>
                        <div className="career-slider-item__text">
                            {Array.isArray(text)
                                ? text.map((el, index)=> <Fragment key={index}><span >{el}</span><br/></Fragment>)
                                : text}
                        </div>
                    </div>
                    </>}
            </div>
        )
    } else {
        return (
            <div
                className={unactive ? "career-slider-item_unactive" : ''}
                onClick={() => {
                    changeChoosenSlide()
                    clearInterval(timer.current as NodeJS.Timer)
                }}>
                {parsedItems?.[choosenSlide]
                    ? <CareerSliderItem
                        nextItem={parsedItems[choosenSlide < parsedItems.length - 1 ? choosenSlide + 1 : 0]}
                        items={parsedItems[choosenSlide]}
                        we={we}
                        multi
                    />
                    : null
                }
            </div>
        )
    }
}
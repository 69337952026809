import React, {useEffect, useState} from "react"

export default function() {
    const [fading, setFading] = useState(false)
    useEffect(() => {
        setTimeout(() => setFading(true), 500)
    }, [])
    return (
        <div className={`page-loader ${fading ? 'page-loader_fading' : ''}`}>
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}
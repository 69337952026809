import { URLSearchParams } from 'url';
import { FoodserviceData } from '../pages/FoodserviceDetails';
import { store } from '../store';
import { BACKEND } from './../config';
import { request } from './http';

export function deleteReciepe(id: string) {

    return request(`${BACKEND}/api/reciepes/delete`, 'POST', {id})
        .then(() => {
            console.log("Рецепт удален")
            store.dispatch({type: 'UPDATE', payload: {}})
        })
        .catch(({message}) => console.log(message))
}

export function getReciepes() {
    return request(`${BACKEND}/api/database/reciepes`, 'GET')
        .then(data => data)
        .catch(({message}) => console.log(message))
}

export function getFoodservice(searchParams?: URLSearchParams) {
    return request(
        `${BACKEND}/api/database/foodservice`,
        'POST',
        searchParams && {filters: Object.keys(Object.fromEntries([...searchParams]))}
    )
        .then(data => data as FoodserviceData)
        .catch(({message}) => console.log(message))
}
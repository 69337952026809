import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonType, routes } from "../../types/controlTypes";
import Button from "../reusable/Button";

const scrollTime = 1500

export default function HeaderButton ({title, to, dropdownItems}: {title: string, to?: routes, dropdownItems?: Array<ButtonType>}) {
    const location = useLocation()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    function clickHandler(to: string | undefined) {
        if (to) {
            // dispatch({type: 'TOGGLE_BLUR', payload: true})
            // window.scrollTo({top: 0, behavior: 'smooth'})
            setOpen(prev => !prev)
            navigate(to)
            // setTimeout(() => {
            //     dispatch({type: 'TOGGLE_BLUR', payload: false})
            // }, scrollTime)
        }
        setOpen(prev => !prev)
    }

    return (
    dropdownItems
        ?   <div
                className={`header-dropdown ${dropdownItems.find(({to}) => location.pathname == '/' + to) ? 'active' : ''}`}
                onClick={() => clickHandler(to)}
            >

                <span>{title}</span>
                <div className={`header-dropdown-list ${open ? 'opened' : ''}`}>
                    {dropdownItems.map((button, index) =>
                        <Button
                            key={index}
                            title={button.title}
                            onClick={() => clickHandler(button.to)}
                        />
                        )}
                </div>
                {open && <div className="header-dropdown-list-dimmer" onClick={() => setOpen(true)}></div>}
            </div>
        :   <div className={`header-button ${location.pathname === '/' + to && 'active'}`} onClick={() => clickHandler(to)}>
                {title}
            </div>
)}
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const devices = {
    desktop: [1200, undefined],
    tab: [800, 1199],
    mobile: [undefined, 799]
} as const

export const getDevice = (width?: number) => width && Object.entries(devices).find(([key, [min, max]]) => width > (min || 0) && width < (max || Infinity))?.[0] as keyof typeof devices

export function useDevice() {
    const [{width, height, device}, setState] = useState<Record<'width' | 'height' | 'device', number | string | undefined>>({width: undefined, height: undefined, device: undefined})
    const location = useLocation()

    useEffect(() =>  {
        window.addEventListener('load', () => setState({width: window.innerWidth, height: window.innerHeight, device: getDevice(window.innerWidth)}))
        return window.removeEventListener('load', () => setState({width: window.innerWidth, height: window.innerHeight, device: getDevice(window.innerWidth)}))
    }, [])
    useEffect(() => {
        window.addEventListener('resize', () => setState({width: window.innerWidth, height: window.innerHeight, device: getDevice(window.innerWidth)}))
        return window.removeEventListener('resize', () => setState({width: window.innerWidth, height: window.innerHeight, device: getDevice(window.innerWidth)}))
    }, [])
    useEffect(() => {
        setState({width: window.innerWidth, height: window.innerHeight, device: getDevice(window.innerWidth)})
    }, [location])

    return {width, height, device} as {width: number, height: number, device: keyof typeof devices}
}

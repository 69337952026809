import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLangFromCookie } from '../components/header/Header';
import Loader from '../components/reusable/Loader';
import SliceListItem from '../components/SliceListItem';
import { BACKEND, getResource } from '../config';
import useTranslate from '../hooks/translate';
import { request } from '../services/http';
import { Curtain } from '../types/controlTypes';
import downloadFile from './../utils/downloadFile';

export type FoodserviceData = {
	curtain: Curtain;
	items: FSItem[];
};

export type FSItem = {
	id: string;
	new?: boolean;
	title: string;
	photo: string;
	photo_position?: 'left' | 'right' | 'top' | 'bottom';
	solutions: string[];
	benefits: string[];
	left: string[];
	right: string[];
	specifications: string[];
};

export default function FoodserviceDetails() {
	const [data, setData] = useState<FSItem>();
	const [specOpen, setSpecOpen] = useState(false);

	const { id } = useParams();

	useEffect(() => {
		request(`${BACKEND}/api/database/foodservice/${id}`, 'GET')
			.then(data => {
				setData(data);
			})
			.catch(({ message }) => console.log(message));
	}, [id]);

	if (!data) return <Loader />;

	return (
		<>
			<div className='food-service-page'>
				<div className='food-service-page__photo'>
					<img src={getResource(data.photo)} />
					<div className='food-service-page__labels'>
						{data.solutions?.map(label => (
							<span key={label} className='food-service-page__label'>
								{label}
							</span>
						))}
					</div>
				</div>
				<div className='food-service-page__top-right'>
					<h1>{data.title}</h1>
					<ul>
						{data.benefits?.map((benefit, index) => (
							<SliceListItem
								key={benefit}
								text={benefit}
								index={index}
								direction='verticalOrder'
							/>
						))}
					</ul>
				</div>
				<div className='food-service-page__bottom-left'>
					<h1>{data.left[0]}</h1>
					<p>{data.left[1]}</p>
				</div>
				{/* <div className='food-service-page__bottom-right'>
					<h1>{data.right[0]}</h1>
					<h3
						className='food-service-page__specs-header'
						onClick={() => setSpecOpen(!specOpen)}
					>
						{data.specifications[0].split('.')[0]}
						<i
							aria-hidden='true'
							className={`fa fa-chevron-${specOpen ? 'up' : 'down'}`}
						></i>
					</h3>
					<ul
						className={`food-service-page__specs ${
							specOpen ? 'food-service-page__specs_open' : ''
						}`}
					>
						{data.specifications?.map(spec => (
							<li
								key={spec}
								onClick={() =>
									downloadFile(getResource(`${id?.toLowerCase()}${getLangFromCookie() == 'eng' ? 'eng' : ''}.pdf`))
								}
							>
								{spec.split('.')[0]}
							</li>
						))}
					</ul>
				</div> */}
				<div className='food-service-page__bottom-right'>
					<h1>{data.right[0]}</h1>
					{data.specifications?.map(spec => (
						<h3
							key={spec}
							className='food-service-page__specs-header'
							onClick={() =>
								downloadFile(
									getResource(
										`${id?.toLowerCase()}${
											getLangFromCookie() == 'eng' ? 'eng' : ''
										}.pdf`
									)
								)
							}
						>
							{spec.split('.')[0]}
						</h3>
					))}
				</div>
			</div>
		</>
	);
}

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReciepeData } from '../components/reciepes/Reciepes';
import Loader from '../components/reusable/Loader';
import Video from '../components/reusable/Video';
import SliceListItem from '../components/SliceListItem';
import { BACKEND, getResource } from '../config';
import {useDevice} from '../hooks/device.hook';
import useNaviScroll from '../hooks/naviScroll.hook';
import { request } from '../services/http';
import { Retail } from './Retail';

export default function ReciepeDetails() {
	const [data, setData] = useState<ReciepeData>();
	const [skus, setSkus] = useState<{ id: string; title: string }[]>();
	const {device} = useDevice()
	const navigate = useNavigate();
	const { go } = useNaviScroll();
	const { id } = useParams();

	useEffect(() => {
		request(`${BACKEND}/api/database/reciepes/${id}`, 'GET')
			.then(data => setData(data))
			.catch(({ message }) => console.log(message));
	}, [id]);

	useEffect(() => {
		request(`${BACKEND}/api/database/skus`, 'GET')
			.then(data => setSkus(data))
			.catch(({ message }) => console.log(message));
	}, [id]);

	if (!data) return <Loader />;

	const { item, texts } = data;

	return (
		<>
			<div className='reciepes-details'>
				<div className='reciepes-details__photo'>
					<div style={{ backgroundImage: `url(${getResource(item.photo)})` }} />
				</div>
				<div className='reciepes-details__top-right'>
					<h1>{item.title}</h1>
					<div className='reciepes-details__icon'>
						<img src={getResource(`clock.svg`)} />
						{texts[5]} {item.time}
					</div>
					<div className='reciepes-details__icon'>
						<img src={getResource(`person.svg`)} />
						{texts[6]} {item.portions}
					</div>
				</div>
				<div className='reciepes-details__bottom-left'>
					<h1>{texts[0]}</h1>
					<ul>
						{item.ingredients?.map((ingredient, index) => (
							<SliceListItem key={ingredient} text={ingredient} index={index} direction='verticalOrder'/>
						))}
					</ul>
				</div>
				<div className='reciepes-details__bottom-right'>
					<h1>{texts[1]}</h1>
					<p>{item.coocking}</p>
				</div>
			</div>
			<h1 className='t_grafit t_mid mt_mid t_center'>{texts[2]}</h1>
			<div className='reciepes-details__retail-products'>
				{/* {retail?.products.map(({photo, title}) =>
                    <div key={title} className='reciepes-details__retail-products__item'>
                        <div className='reciepes-details__retail-products__photo' style={{backgroundImage: `url(${getResource(photo)})`}}/>
                        <h3>{title}</h3>
                    </div>
                )} */}
				{/* {retail?.products && [1, 0, 2, 3].map(order => {
                    const {title, photo} = retail?.products[order]
                    return <div key={title} className='reciepes-details__retail-products__item'>
                            <div className='reciepes-details__retail-products__photo' style={{backgroundImage: `url(${getResource(photo)})`}}/>
                            <h3>{title}</h3>
                        </div>
                    }
                )} */}
				{data.item.ids
					.split(',')
					.filter(_id =>
						skus?.some(
							({ id }) => _id.trim().toLowerCase() == id.trim().toLowerCase()
						)
					)
					.map((_id, index) => {
						const { title, id } =
							skus?.find(
								({ id }) => _id.trim().toLowerCase() == id.trim().toLowerCase()
							) || {};
						return (
							<div
								onClick={() =>
									id?.startsWith('r')
										? navigate('/retail')
										: navigate(`/foodservice/${id}`)
								}
								key={index}
								className='reciepes-details__retail-products__item'
							>
								<div
									className='reciepes-details__retail-products__photo'
									style={{
										backgroundImage: `url(${getResource(
											`${id?.toLowerCase()}.png`
										)})`,
									}}
								/>
								<h3>{title}</h3>
							</div>
						);
					})}
			</div>
			{item.video && <Video width={device == 'desktop' ? '480px' : ''} height='auto' url={getResource(item.video)} />}
			<div className='reciepes-details__bottom-buttons'>
				<div onClick={() => go(texts[3][0])}>
					<h3>{texts[3][1]}</h3>
					<div>
						<p>{texts[3][2]}</p>
						<span className='material-icons'>chevron_right</span>
					</div>
				</div>
				<div onClick={() => go(texts[4][0])}>
					<h3>{texts[4][1]}</h3>
					<div>
						<p>{texts[4][2]}</p>
						<span className='material-icons'>chevron_right</span>
					</div>
				</div>
			</div>
		</>
	);
}

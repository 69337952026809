import React from "react";
import { getResource } from "../../config";

export default function ({title, img, choosen, onClick, onHover}:
    {title: string, img: string, media: string, text: string, choosen: boolean, onClick: () => void, onHover: (exit?: true) => void}
) {

    return (
        <>
            {/* <div className="story-slider__divider"/> */}
            <div
                className={'story-slider__item'}
                onClick={onClick}
                onMouseEnter={() => onHover()}
                onMouseLeave={() => onHover(true)}
            >
                <div className='story-slider__item__img'>
                    <img src={getResource(choosen ? 'slice_green.svg' : `slice_${img}.svg`)}></img>
                </div>
                <span className="story-slider__item__text">{title}</span>
            </div>
        </>
    )
}
import React, { useEffect, useMemo, useState } from 'react';
import { BACKEND, getResource } from './../config';
import { useSearchParams } from 'react-router-dom';
import { request } from '../services/http';
import { useDevice } from '../hooks/device.hook';
import Curtain from '../components/reusable/Curtain';
import Filters from '../components/foodservice/Filters';
import Reciepe from '../components/reciepes/Reciepe';
import Loader from '../components/reusable/Loader';
import { FoodserviceData } from './FoodserviceDetails';

export default function () {
	const [data, setfsItems] = useState<FoodserviceData>();
	const { curtain, items } = data || {};
	const { description, img, title } = curtain || {};
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const { device } = useDevice();

	const [filtersOpened, setFiltersOpened] = useState(false);

	useEffect(() => {
		const getData = () =>
			request(`${BACKEND}/api/database/foodservice`, 'POST', {
				filters: [...searchParams.entries()],
			})
				.then(data => setfsItems(data))
				.catch(({ message }) => console.log(message))
				.finally(() => setLoading(false));

		setLoading(true);
		setTimeout(getData, 300);
	}, [searchParams]);

	const reciepes = useMemo(
		() =>
			!loading ? (
				items?.map((item, index) => {
					const { id, title, photo_position } = item;
					return (
						<Reciepe
							key={index + id}
							new={item.new}
							img={`${id}.png`}
							imgPosition={photo_position}
							id={id}
							title={title}
							to={id}
							index={index}
							type={'foodservice'}
							width={31}
						/>
					);
				})
			) : (
				<Loader />
			),
		[loading, items]
	);

	return (
		<>
			<Curtain
				title={title || ''}
				description={description}
				imgUrl={getResource(img || '')}
			/>
			{device != 'desktop' && (
				<span
					className='filters-toggle-button'
					onClick={() => setFiltersOpened(opened => !opened)}
				>
					<i className='fa fa-filter'></i>
				</span>
			)}
			{device == 'desktop' ? (
				<div className='panels'>
					<Filters />
					<div className='reciepes reciepes_thin'>{reciepes}</div>
				</div>
			) : (
				<>
					<span
						className={`filters-panel ${
							filtersOpened ? 'filters-panel_opened' : ''
						}`}
					>
						<Filters />
					</span>
					<div className='reciepes'>{reciepes}</div>
				</>
			)}
		</>
	);
}

// ="ET11183.jpg" ИЛИ "ET12212.jpg" ИЛИ "ET11845.jpg"ИЛИ "ET11882.jpg"ИЛИ "ET11554.jpg"ИЛИ "ET11224.jpg"ИЛИ "ET11954.jpg"ИЛИ "ET11359.jpg"ИЛИ "ET11477.jpg"ИЛИ "ET11745.jpg"ИЛИ "ET11745.jpg"ИЛИ "ET12135.jpg"ИЛИ "ET12135.jpg"ИЛИ "ET12246.jpg"ИЛИ "ET12246.jpg"ИЛИ "ET12233.jpg"ИЛИ "ET12233.jpg"ИЛИ "ET11815.jpg"ИЛИ "ET11849.jpg"ИЛИ "ET122263.jpg"ИЛИ "ET122263.jpg"

import React from "react";
import { useNavigate } from 'react-router-dom';
import { getResource } from "../../config";
import RoundedButton from "../reusable/RoundedButton";

export type SliderItemProps = {
    img:string
    header: string
    toURL: string
    index?: number
}

export default function SliderItem({img, header, toURL}: SliderItemProps) {
    const navigate = useNavigate()
    return (
        <div className="slider-item" style={{backgroundImage: `url(${getResource(img)})`}}>
            <div className="slider-wrapper">
                <h1 className="t_white t_active t_head t_contrast" onClick={()=>navigate(toURL)}>{header}</h1>
            </div>
        </div>
    )
}
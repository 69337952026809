import { createStore } from 'redux'
import { ReciepeSchema } from './components/reciepes/Reciepes'

export type TinitialState = {
    blur: boolean,
    language: 'rus' | 'eng'
    user: {
        _id: string,
        jwtToken: string,
        expire: string,
      },
    update: boolean,
    admin: boolean
    reciepes: ReciepeSchema[]
    langmap: Record<string, string>,
    modals: Record<string, boolean>,
    popup: string
}

const storeName = 'wefry'

const initialState: TinitialState = {
    blur: false,
    language: 'rus',
    update: false,
    admin: true,
    reciepes: [],
    user: {
        _id: '',
        jwtToken: '',
        expire: '',
    },
    langmap: {},
    modals: {
        feedback: false
    },
    popup: '',
}

function updateLocalStorage(state: TinitialState): void {
    localStorage.setItem(storeName, JSON.stringify(state))
  }
  function getLocalStorage(): TinitialState | null {
    let ls = localStorage.getItem(storeName) || null
    if (ls) {
      return JSON.parse(ls)
    }
    return null
  }

function rootReducer(state = initialState, action: {type: string, payload: any}) {
    switch (action.type) {
        case 'TOGGLE_POPUP':
            return {...state, popup: action.payload}
        case 'TOGGLE_MODAL':
            return {...state, modals: {...state.modals, [action.payload]: !state.modals[action.payload]}}
        case 'TOGGLE_BLUR':
            return { ...state, blur: action.payload }
        case 'INIT_STORAGE':
            return { ...action.payload }
        case 'SETLANGMAP':
            return { ...state, langmap: action.payload }
        case 'LOGIN':
            return { ...state, user: action.payload.user}
        case 'LOGOUT':
            return { ...initialState }
        case 'GET_RECIEPES':
            return {
                ...state,
                reciepes: action.payload,
            }
        case 'UPDATE':
            return {
                ...state,
                update: !state.update
            }
        case 'CHANGE_LANGUAGE':
            return {
                ...state,
                language: state.language === 'rus' ? 'eng' : 'rus'
            }
        default:
            return {...state}
    }
}

export let store = createStore(rootReducer)
getLocalStorage() && store.dispatch({ type: 'INIT_STORAGE', payload: { ...getLocalStorage() } })
store.subscribe(() => updateLocalStorage(store.getState()))
import React, { useEffect, useState, useMemo, useRef } from "react";
import SliderItem, { SliderItemProps } from './SliderItem';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation} from "swiper";

import { HomeData } from "../../types/controlTypes";

export default function ({slides}: {slides: HomeData['slides']}) {
    const [slide, setSlide] = useState<number>(0)
    const [swiper, setSwiper] = useState<any>(null)
    const nextButtonRef = useRef<HTMLSpanElement>(null)


    useEffect(() => {
        const interval = setInterval(
            () => nextButtonRef.current?.click(),
            7000
        )
        return () => clearInterval(interval)
    }, [])

    const circles = useMemo(
        () => slides
            .map((_, index) =>
                <div
                    key={index + 'circle'}
                    onClick={() => swiper.slideTo(index, 500)}
                    className={`material-icons circle ${index === slide ? 'active' : ''}`}
                >
                    brightness_1
                </div>),
        [slide, slides]
    )

    return (
        <div className="slider-container">
            <div className="slider">
                <div className="slider-wrapper">
                    <div className="slider-controls">
                        {/* <div className="slider-controls-buttons">
                            <div><span className="material-icons home-slide-prev">chevron_left</span></div>
                            <div><span ref={nextButtonRef} className="material-icons home-slide-next">chevron_right</span></div>
                        </div> */}
                        {circles}
                    </div>
                </div>
                <Swiper
                    onSwiper={setSwiper}
                    onSlideChange={(swiper: any) => setSlide(swiper.realIndex)}
                    navigation={{
                        nextEl: '.home-slide-next',
                        prevEl: '.home-slide-prev'
                    }}
                    modules={[Navigation]}
                    className="mySwiper"
                    rewind
                >
                    {slides.map(({header, img, toURL}, index)=>
                        <SwiperSlide key={index + toURL}>
                            <SliderItem
                                key={index+toURL}
                                header={header}
                                img={img}
                                toURL={toURL}
                                index={index+1}/>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    )
}
import React, { useEffect, useMemo, useState } from 'react';
import Reciepe from './Reciepe';
import { getFoodservice, getReciepes } from '../../services/reciepe';
import { FSItem } from '../../pages/FoodserviceDetails';
import { shuffle } from '../../utils/shuffle';
import { getDevice, useDevice } from '../../hooks/device.hook';

export type ReciepeSchema = {
	id: string;
	ids: string;
	title: string;
	photo: string;
	video: string;
	time: string;
	portions: string;
	ingredients: string[];
	coocking: string;
};

export type ReciepeData = {
	item: ReciepeSchema;
	texts: string[];
	curtain: { title: string; description: string };
};

export type ReciepesData = {
	items: ReciepeSchema[];
	texts: string[] | string[][];
};

export default function () {
	const [reciepes, setReciepes] = useState<ReciepeSchema[]>([]);
	const [fs, setFs] = useState<FSItem[]>([]);
	const { width } = useDevice();
	const loadReciepes = () => getReciepes();
	const loadFoodservice = () => getFoodservice();

	useEffect(() => {
		loadReciepes().then(data => data && setReciepes(data.items));
		loadFoodservice().then(data => data && setFs(data.items));
	}, []);

	const device = useMemo(() => getDevice(width), [width]);
	const _reciepes = useMemo(
		() => shuffle([...reciepes, ...fs]),
		[reciepes, fs]
	);

	return device == 'desktop' ? (
		<div className='reciepes'>
			{_reciepes.map(({ photo, id, title, type }, index) => {
				let wide = 47;
				let short = 22.5;
				let numbers = [
					short,
					short,
					wide,
					wide,
					wide,
					wide,
					short,
					short,
					wide,
					wide,
				];
				return (
					<Reciepe
						type={type ? 'foodservice' : 'reciepes'}
						key={index + id}
						img={photo}
						id={id}
						title={title}
						width={
							numbers[
								Math.abs(
									index < numbers.length
										? index
										: index -
												numbers.length * Math.floor(index / numbers.length)
								)
							]
						}
					/>
				);
			})}
		</div>
	) : (
		<div className='reciepes'>
			{_reciepes.map(({ photo, id, title, type }, index) => (
				<Reciepe
					type={type ? 'foodservice' : 'reciepes'}
					key={index + id}
					img={photo}
					id={id}
					title={title}
				/>
			))}
		</div>
	);
}

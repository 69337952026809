import React from "react";
import { getResource } from "../config";

type Props = string[][]

export default function({cards}: {cards: Props}) {

    return (
        <div className="contacts-cards">
            {cards.map(([avt, name, description, phone, mail], index) =>
                <div key={index} className="contacts-cards__card">
                    <div className="contacts-cards__card-header">
                        <img src={getResource(avt)} title={name}/>
                        <p>{name}</p>
                    </div>
                    <p className="contacts-cards__description">{description}</p>
                    <div className="contacts-cards__contacts">
                        <span className="contacts-cards__contact-row">
                            <img src={getResource("./icon_tel.svg")}/>
                            <a className="contacts-cards__tel" href={`tel:${phone}`}>
                                {phone}
                            </a>
                        </span>
                        <span className="contacts-cards__contact-row">
                            <img src={getResource("./icon_mail.svg")}/>
                            <a className="contacts-cards__mail" href={`mailto:${mail}`}>
                                {mail}
                            </a>
                        </span>
                    </div>
                </div>
                )
            }
        </div>
    )
}
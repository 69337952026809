import React, { useEffect, useState } from "react"
import Reciepes from "../components/reciepes/Reciepes";
import RoundedButton from "../components/reusable/RoundedButton";
import Video from "../components/reusable/Video";
import Slider from '../components/slider/HomeSlider';
import useNaviScroll from "../hooks/naviScroll.hook";
import { request } from "../services/http";
import { HomeData } from "../types/controlTypes";
import { BACKEND, getResource } from './../config';

export default function Home() {
    const [data, setData] = useState<HomeData>()
    const {cards, slides, reciepeTitle, video, button} = data || {}
    const {go} = useNaviScroll()

    useEffect(
        () => {
            request(`${BACKEND}/api/database/home`, 'GET')
                .then((data) => {
                   setData(data)
                })
                .catch(({message}) => console.log(message))
        },
        []
    )

    return (
        <>
            <Slider slides={slides || []}/>
            <div className="square-3">
                {cards?.map(([header, text, img, route]) =>
                    <div key={text} className="card">
                        <img src={getResource(img)}/>
                        <h2>{header}</h2>
                        <p>{text}</p>
                        <RoundedButton title={button || ''} onClick={() => go(route)}/>
                    </div>
                )}
            </div>
            <div className="reciepes-header">
                <img src={getResource(reciepeTitle?.[0] || '')}></img>
                <h2>{data?.reciepeTitle[1]}</h2>
            </div>
            <Reciepes/>
            {video && <Video id='presentation' url={getResource(video[0])} poster={getResource(video[1])}/>}
        </>

    )
}
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import EasySlider from "../components/easySlider/EasySlider";
import Curtain from "../components/reusable/Curtain"
import SimpleSlider from "../components/simpleSlider/SimpleSlider";
import StorySlider, { StoryItem } from "../components/storySlider/StorySlider";
import { BACKEND, getResource } from './../config';
import { request } from "../services/http";
import { splitEachString } from "../App";

export default function About() {
    const navigate = useNavigate()
    const [data, setData] = useState<{[key: string]: string | string[]}>()
    const {
        block1, block2, block3, block3Array, block4, block4Array, block5Array, block6, block7, block8,
        block9, button, block10, story, block12, photos, curtain
    } = data || {}

    useEffect(
        () => {
            request(`${BACKEND}/api/database/about`, 'GET')
                .then((data) => {
                   setData(data)
                })
                .catch(({message}) => console.log(message))
        },
        []
    )

    if (!data)
        return null

    return (
        <>
            <Curtain title={(curtain as unknown as {title: string}).title} imgUrl={getResource('about_head_img.png')}/>
            <h1 style={{textAlign: 'center'}} className="t_wide t_grafit mt_big t_bold">
                {block1}
            </h1>
            <p style={{textAlign: 'justify'}} className="t_wide t_grafit">
                {block2}
            </p>
            <h3 className="t_terrakot t_mid">
                {block3}
            </h3>
            <div className="about-cards">
                {splitEachString([...block3Array]).map(({img, text}) =>
                    <div key={text} className="about-cards__card">
                        <div style={{backgroundImage: `url(${getResource(img)})`}} className='about-cards__img'/>
                        <p>{text}</p>
                    </div>)}
            </div>
            <h3 className="t_terrakot">
                {block4}
            </h3>
            <ul className="about-list">
                {splitEachString([...block4Array]).map(({img, text}, index) =>
                    <li key={index}>
                        <img src={getResource(img)}/>
                        {text}
                    </li>
                )}
            </ul>
            <SimpleSlider items={[...photos || []].map(photo => getResource(photo))}/>
            <div className="about-slice-cards">
                {splitEachString([...block5Array]).map(({img, text}) =>
                    <div className="about-slice-cards__card">
                        <div style={{backgroundImage: `url(${getResource(img)})`}} className='about-slice-cards__img'/>
                        <p className="t_18">{text}</p>
                    </div>
                )}
            </div>
            <h1 className="t_grafit t_mid mt_big t_center t_bold">
                {block6}
            </h1>
            <h3 style={{whiteSpace: 'pre-line'}} className="t_terrakot t_mid mt_ltl t_center">
                {block7}
            </h3>
            <h1 className="t_grafit t_mid mt_ltl t_center t_bold">
                {block8}
            </h1>
            <p className="t_grafit t_mid mt_big t_center">
                {block9}
            </p>
            <div className="rounded-button rounded-button-about rounded-button_orange" onClick={() => navigate('/field_to_dish')}>
                {button}
            </div>
            <h2 className="t_grafit t_mid mt_mid t_center">
                {block10}
            </h2>
            <StorySlider items={story as unknown as StoryItem[]}/>
            {/* <EasySlider
                items={new Array(10).fill('reward_', 0, 10).map((el, i) => getResource(el + (i + 1) + '.jpg'))}
                title={block12 as string}
            /> */}
        </>
    )
}
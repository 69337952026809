import React from 'react';
import Curtain from '../components/reusable/Curtain';
import { getResource } from '../config';
import { useDevice } from '../hooks/device.hook';
import downloadFile from '../utils/downloadFile';

export default function () {
	const { device } = useDevice();
	const wasteTypes = [
		['p1.jpg', '#slide1_name', 'Картофель сырой резаный'],
		['p2.jpg', '#slide2_name', 'Выбракованный картофель'],
		['p3.jpg', '#slide3_name', 'Обрезки обжаренные'],
		['p4.jpg', '#slide4_name', 'Смесь крахмальная'],
		['p5.jpg', '#slide5_name', 'Картофельная мезга'],
	];

	const separateWaste = [
		['Батарейки', 'eco1.jpeg'],
		['Стекло', 'eco2.png'],
		['Пластик', 'eco3.png'],
		['Бумага', 'eco4.jpeg'],
	];

	return (
		<>
			<Curtain
				title='Экологические инициативы'
				// description={`Ответственное отнощение к окружающей среде\nОбращение с отходами, бизнес партнерство`}
				imgUrl={getResource('wasteCurtain.jpg')}
			/>
			<span className='page-content'>
				<h1
					style={{ textAlign: 'center' }}
					className='t_wide t_grafit mt_big t_bold'
				>
					Раздельный сбор мусора
				</h1>
				<h3
					style={{ textAlign: 'center' }}
					className='t_wide t_terrakot t_hover-yellow t_margin-y-15'
					onClick={() => downloadFile(getResource(`экология.pdf`))}
				>
					Экологическая политика Ви Фрай&nbsp;&nbsp;
					<i className='fa fa-download t_terrakot' />
				</h3>
				<div className='waste-separate-cards'>
					{separateWaste.map(([title, image]) => (
						<img src={getResource(image)} />
					))}
				</div>

				<h1
					style={{ textAlign: 'center' }}
					className='t_wide t_grafit mt_mid t_bold t_margin-y-15'
				>
					Реализация отходов
				</h1>
				<div className='waste-page'>
					<div className='waste-types'>
						{wasteTypes.map(([photo, link, text]) => (
							<div className='waste-types__type'>
								<a href={link}>
									<div
										className='waste-types__image'
										style={{ backgroundImage: `url(${getResource(photo)}` }}
									>
										<div className='menu-tab-text'>
											<h3>{text}</h3>
										</div>
									</div>
								</a>
							</div>
						))}
					</div>
					{/*Слайд №1*/}
					<span className='waste-divider'>
						<img src={getResource('icon_speak.svg')} />
					</span>
					<div id='slide1_name'>
						<div className='wrapper'>
							<div className='box a waste-blue'>
								<h3 className='type-waste-title type-waste-title_white waste-terrakot'>
									<strong>Картофель сырой резаный</strong>
								</h3>
								<ul className='colored-list'>
									<li>Образуется в процессе резки</li>
									<li>Загружается в транспорт навалом</li>
									<li>Транспортируется в контейнерах объемом 18м3</li>
									<li>Производится 120 тонн в сутки</li>
								</ul>
							</div>
							<div className='box b waste-grafit'>
								<h3 className='waste-terrakot_text'>Характеристика отхода</h3>
								<ul className='waste-white_text white-list'>
									<li>сухое вещество - 23,4%</li>
									<li>крахмал - 7%</li>
									<li>перевариваемое органическое вещество - 21%</li>
									<li style={{ listStyle: 'none', marginTop: '5px'  }}>
										<a
											className='t_active-white-terrakot'
											href={getResource('rawpot.pdf')}
										>
											<strong>подробнее...</strong>
										</a>
									</li>
								</ul>
							</div>
							<div
								className='box d'
								style={{
									backgroundImage: `url(${getResource(
										'ustanovk_po_zagruzke.jpg'
									)}`,
									backgroundPosition: 'center',
									backgroundSize: 'cover',
								}}
							>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img
										src={getResource('home_ico3.svg')}
										style={{ width: '128px', float: 'left' }}
										alt=''
									/>
									<p className='waste-skin_text'>
										Установка по загрузке отхода в контейнер
									</p>
								</div>
							</div>
						</div>
						<div className='wrapper'>
							<div
								className='box full'
								style={{
									backgroundImage: `url(${getResource('kuch_kartoshki.jpg')}`,
									backgroundPosition: 'center',
									backgroundSize: 'cover',
								}}
							>
								<div className='box waste-grafit_transparent'>
									<h3 className='waste-terrakot_text'>Способ применения</h3>
									<p>
										Картофель сырой резаный смешивается с кукурузным или
										свекольным жомом, после чего компостируется. Полученная
										после компостирования кашица легко усваивается крупным
										рогатым скотом, при этом сохраняя баланс бактерий в рубце.
									</p>
								</div>
							</div>
						</div>
					</div>
					<span className='waste-divider'>
						<img src={getResource('icon_speak.svg')} />
					</span>
					{/*Слайд №2*/}
					<div id='slide2_name'>
						<div
							className='wrapper'
							style={{
								backgroundImage: `url(${getResource('vibrak_kartof.jpg')}`,
								backgroundPosition: 'center',
								backgroundSize: 'cover',
							}}
						>
							<div className='box a waste-grafit_transparent'>
								<h3 className='waste-terrakot_text '>
									<strong>Выбракованный картофель</strong>
								</h3>
								<ul className='colored-list'>
									<li>
										Образуется в процессе сортировки картофеля на участке
										приемки сырья
									</li>
									<li>Загружается в транспорт навалом</li>
									<li>Транспортируется в контейнерах объемом 18м3</li>
									<li>Производится 10 тонн в сутки</li>
								</ul>
							</div>
							<div className='box e waste-grafit_transparent'>
								<h3 className='waste-yellow_text'>Характеристика отхода</h3>
								<ul className='white-list'>
									<li>сухое вещество - от 19% и выше</li>
									<li>крахмал - от 13% и выше</li>
									<li>размер картофеля от 3 до 4,5 см</li>
								</ul>
							</div>
							<div className='box d'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img
										src={getResource('home_ico3.svg')}
										style={{ width: '128px', float: 'left' }}
										alt=''
									/>
									<p
										className='waste-grafit_transparent'
										style={{ paddingLeft: '10px' }}
									>
										Выбракованный картофель в момент отгрузки
									</p>
								</div>
							</div>
						</div>
						<div
							className='wrapper'
							style={{
								backgroundImage: `url(${getResource('vibrak_kartof2.jpg')}`,
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								marginTop: '0.6rem',
							}}
						>
							<div className='box full'>
								<div
									className='box waste-grafit_transparent'
									style={{ width: '83%' }}
								>
									<h3 className='waste-yellow_text'>Особенности отхода</h3>
									<p>
										<img
											src={getResource('home_ico2.svg')}
											style={{
												float: 'right',
												height: '195px',
												marginRight: '-130px',
												marginTop: '-86px',
											}}
											alt=''
										/>
										Имеет высокое содержание крахмала. По своему составу и
										фракции отлично подходит для производства технического
										крахмала
									</p>
								</div>
							</div>
						</div>
					</div>
					<span className='waste-divider'>
						<img src={getResource('icon_speak.svg')} />
					</span>
					{/*Слайд №3*/}
					<div id='slide3_name'>
						<div className='wrapper'>
							<div className='box a waste-grafit'>
								<h3 className='type-waste-title waste-terrakot waste-white_text'>
									<strong>Обрезки обжаренные</strong>
								</h3>
								<ul className='colored-list'>
									<li>Образуются в процессе холодной сортировки</li>
									<li>Загружаются в транспорт навалом</li>
									<li>Производятся 10-15 тонн в сутки</li>
								</ul>
							</div>
							<div className='box b waste-blue'>
								<h3 className='waste-grafit_text waste-yellow_text'>
									Характеристика отхода
								</h3>
								<ul className='white-list'>
									<li>сухое вещество - 33.5%</li>
									<li>содержание масла - 6%</li>
									<li>перевариваемое органическое вещество - 29%</li>
									<li style={{ listStyle: 'none', marginTop: '5px'  }}>
										<a
											className='t_active-yellow-terrakot'
											href={getResource('friedpot.pdf')}
										>
											<strong>подробнее...</strong>
										</a>
									</li>
								</ul>
							</div>
							<div
								className='box c'
								style={{
									backgroundImage: `url(${getResource('kartosha_sverkhu.jpg')}`,
									backgroundPosition: 'center',
									backgroundSize: 'cover',
								}}
							>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<p
										style={{ padding: '1rem' }}
										className='waste-grafit_transparent'
									>
										Обрезки обжаренные - это картофель, который отбраковывается
										из-за наличия дефектов: нехарактерная структура, цвет и
										другие показатели, которые обнаруживаются на заключительном
										этапе производства картофеля фри.
									</p>
								</div>
							</div>
							<div
								className='box e'
								style={{
									backgroundImage: `url(${getResource('korobok.jpg')}`,
									backgroundPosition: 'top',
									backgroundSize: 'cover',
								}}
							>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img
										src={getResource('home_ico3.svg')}
										style={{ width: '128px', float: 'left' }}
										alt=''
									/>
									<p
										className='waste-grafit_transparent'
										style={{ padding: '1rem' }}
									>
										Контейнер для транспортировки обрезков обжаренных
									</p>
								</div>
							</div>
						</div>
						<div className='wrapper'>
							<div
								className='box full'
								style={{
									backgroundImage: `url(${getResource(
										'kucha_kartoshki_boke.jpg'
									)}`,
									backgroundPosition: 'bottom',
									backgroundSize: 'cover',
								}}
							>
								<div
									className='box waste-grafit_transparent'
									style={{
										width: '85%',
										marginLeft: '15%',
									}}
								>
									<h3 className='waste-yellow_text'>Способ применения</h3>
									<p>
										<img
											src={getResource('home_ico2.svg')}
											style={{
												float: 'left',
												marginLeft: '-122px',
												marginTop: '-40px',
											}}
											alt=''
										/>
										Отличительная особенность - содержание растительного масла в
										картофеле.
										<br />
										{device != 'desktop' && <br />}
										Среди кормового сырья жиры являются основным поставщиком
										энергии, жиросодержащих витаминов и жизненно необходимых
										жирных кислот. Именно по этой причине этот вид отхода
										пользуется большим спросом на свинофермах.
									</p>
								</div>
							</div>
						</div>
					</div>
					<span className='waste-divider'>
						<img src={getResource('icon_speak.svg')} />
					</span>
					{/*Слайд №4*/}
					<div id='slide4_name'>
						<div
							className='wrapper'
							style={{
								backgroundImage: `url(${getResource('krakhmal_meshok.jpg')}`,
								backgroundPosition: 'center',
								backgroundSize: 'cover',
							}}
						>
							<div
								className='box a'
								style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
							>
								<h3 className='type-waste-title waste-blue'>
									<strong>Смесь крахмальная </strong>
									<small>(для технических целей)</small>
								</h3>
								<ul className='waste-grafit_text colored-list'>
									<li>
										Образуется в процессе фильтрации воды, задействованной на
										участке резки картофеля
									</li>
									<li>Собирается в упаковку “биг-бег”</li>
									<li>Производится 5-6 тонн в сутки</li>
									<li>Сертифицирована</li>
								</ul>
							</div>
							<div className='box e waste-blue_transparent'>
								<h3>Характеристика отхода</h3>
								<ul className='white-list'>
									<li>сухое вещество - 56%</li>
									<li>кислотность - 15%</li>
									<li style={{ listStyle: 'none', marginTop: '5px'  }}>
										<a
											className='t_active-white-terrakot'
											href={getResource('starch.pdf')}
										>
											<strong>подробнее...</strong>
										</a>
									</li>
								</ul>
							</div>
							<div className='box d'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img
										src={getResource('home_ico3.svg')}
										style={{ width: '128px', float: 'left' }}
										alt=''
									/>
									<p
										className='waste-grafit_transparent'
										style={{ padding: '0.3rem' }}
									>
										Партия смеси крахмальной в упаковке "биг-бег"
									</p>
								</div>
							</div>
						</div>
						<div
							className='wrapper'
							style={{
								backgroundColor: 'rgba(215, 214, 204, 1)',
								marginTop: '0.6rem',
							}}
						>
							<div
								className='box ac'
								style={{
									backgroundColor: 'rgba(215, 214, 204, 1)',
									// marginTop: '0.3rem',
									height: '20vh',
									padding: 0,
								}}
							>
								<div
									style={{
										zIndex: 1000,
										width: '100%',
										margin: 'auto',
										height: '33vh',
										backgroundImage: `url(${getResource('ustanovka.jpg')}`,
										backgroundPosition: 'center',
										backgroundSize: 'cover',
									}}
								/>
							</div>
							<div className='box be waste-blue' style={{ padding: 0 }}>
								<h3
									className='waste-white_text'
									style={{
										margin: 'auto',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<img
										src={getResource('home_ico2.svg')}
										style={{
											zIndex: 1000,
											height: '130px',
											float: 'left',
											marginTop: '-10px',
											marginBottom: '-10px',
										}}
										alt=''
									/>
									Формирование партии смеси крахмальной
								</h3>
							</div>
							<div
								className='box g'
								style={{
									backgroundImage: `url(${getResource('meshki.jpg')}`,
									backgroundPosition: 'top',
									backgroundSize: 'cover',
									// marginTop: '0.3rem',
									height: '35vh',
								}}
							/>
						</div>
					</div>
					<span className='waste-divider'>
						<img src={getResource('icon_speak.svg')} />
					</span>
					{/*Слайд №5*/}
					<div id='slide5_name'>
						<div
							className='wrapper'
							style={{
								backgroundImage: `url(${getResource('mezga.jpg')}`,
								backgroundPosition: 'center',
								backgroundSize: 'cover',
							}}
						>
							<div className='box a waste-grafit_transparent'>
								<h3 className='type-waste-title waste-terrakot'>
									<strong>Картофельная мезга</strong>
								</h3>
								<ul className='colored-list'>
									<li>Образуется в процессе паровой очистки картофеля</li>
									<li>Транспортируется ассенизаторскими машинами</li>
									<li>Производится 27 тонн в сутки</li>
								</ul>
							</div>
							<div className='box e waste-grafit_transparent'>
								<h3 className='waste-terrakot_text'>Характеристика отхода</h3>
								<ul className='white-list'>
									<li>сухое вещество - 10%</li>
									<li>содержание крахмала - 1,6%</li>
									<li>перевариваемое органическое вещество - 7,5%</li>
									<li style={{ listStyle: 'none', marginTop: '5px' }}>
										<a
											className='t_active-white-terrakot'
											href={getResource('mezg.pdf')}
										>
											<strong>подробнее...</strong>
										</a>
									</li>
								</ul>
							</div>
							<div className='box d'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img
										src={getResource('home_ico3.svg')}
										style={{ width: '128px', float: 'left' }}
										alt=''
									/>
									<p
										className='waste-grafit_transparent'
										style={{ padding: '0.3rem' }}
									>
										Картофельная мезга в контейнере
									</p>
								</div>
							</div>
						</div>
						<div
							className='wrapper'
							style={{
								backgroundImage: `url(${getResource('korob_s_mezgoy.jpg')}`,
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								marginTop: '0.6rem',
							}}
						>
							<div className='box full'>
								<div
									className='box waste-grafit_transparent'
									style={{ width: '83%' }}
								>
									<h3 className='waste-terrakot_text'>Особенности отхода</h3>
									<p>
										<img
											src={getResource('home_ico2.svg')}
											style={{
												float: 'right',
												height: '130px',
												marginTop: '-33px',
												marginRight: '-97px',
											}}
											alt=''
										/>
										Фактически представляет собой картофельную кожуру с высоким
										содержанием влаги. Картофельную мезгу применяют при
										приготовлении корма для КРС, смешивая ее с силосом.
									</p>
								</div>
							</div>
						</div>
					</div>
					<span className='waste-divider'>
						<img src={getResource('icon_speak.svg')} />
					</span>
					{/*Слайд №6*/}
					<div id='slide6_name'>
						<div className='waste-contacts'>
							<div className='waste-grafit waste-white_text'>
								<h3 className='type-waste-title waste-terrakot'>
									<strong>Контактная информация: </strong>
								</h3>
								<h3>Общество с ограниченной ответственностью</h3>
								<h1 className='waste-white_text'>“Ви Фрай”</h1>
								<ul className=''>
									<li>
										<span>Адрес:</span>
										<span>
											399071, Липецкая область, Грязинский р-н, г. Грязи, территория ОЭЗ ППТ ЛИПЕЦК, стр. 67
										</span>
									</li>
									<li>
										<span>Контакты:</span>
									</li>
									<li>
										<span>Телефон:</span>
										<a href='tel:+7(474)272-30-00'>+7 (4742) 72-30-00</a>
									</li>
									<li>
										<span>По вопросам реализации отходов:</span>
									</li>
									<li>
										<span>Электронная почта:</span>
										<a href='mailto:info@lwbd.ru'>waste@wefry.ru</a>
									</li>
									<li>
										<span>Телефон:</span>
										<a href='tel:+7-962-351-80-89'>+7-962-351-80-89</a>
									</li>
								</ul>
							</div>
							<div
								className='waste-blue'
								style={{
									display: 'block',
									textAlign: 'center',
								}}
							>
								<span className='waste-contacts__bye'>
									<img
										style={{ width: '10rem' }}
										src={getResource('home_ico1.svg')}
										alt=''
									/>
									<h2
										className='waste-yellow_text'
										style={{
											marginTop: '5rem',
											marginBottom: '5rem',
										}}
									>
										Будем рады сотрудничеству!
									</h2>
								</span>
							</div>
						</div>
					</div>
				</div>
			</span>
		</>
	);
}

import React, { ChangeEvent, FormEvent, useState } from "react";
import { BACKEND } from "../config";
import { useHttp } from "../hooks/http.hook";
import Input from "./reusable/Input";
import { useNavigate } from 'react-router-dom';
import { TUser, useAuth } from "../hooks/auth.hook";

export default function () {
    const [state, setState] = useState<Record<string, string>>()
    const {request} = useHttp()
    const navigate = useNavigate()
    const {login} = useAuth()

    function inputHandler(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setState({...state, [e.target.name]: e.target.value})
    }
    function onSubmit(event: FormEvent) {
        event.preventDefault()
        request(`${BACKEND}/api/auth/login`, 'POST', state, undefined, false)
            .then((res) => login(res as unknown as TUser))
            .then(() => {
                console.log("Вы зашли как админ")
            })
            .catch(({message})=>console.log(message))
    }


    return (
        <>
            <div className="modal active">
                <div className="modal-header">Войти<i onClick={() => navigate('/home')} className="fa fa-close"></i></div>
                <form onSubmit={onSubmit} className="modal-input" encType="multipart/form-data">
                    <Input
                        placeholder="логин"
                        value={state?.login as string || ''}
                        onChange={inputHandler}
                        name='login'
                    />
                    <Input
                        type='password'
                        placeholder="пароль"
                        value={state?.password as string || ''}
                        onChange={inputHandler}
                        name='password'
                    />
                    <button type="submit" className="submit-button">Войти</button>
                </form>
            </div>
            <div onClick={() => navigate('/home')} className='modal-dimmer active'/>
        </>
    )
}
import React from "react";

export type ButtonProps = {
    onClick: ()=>void
    title: string
    icon?: string
    color?: 'orange' | 'blue' | 'yellow' | undefined
}

export default function Button({onClick, title, icon, color}: ButtonProps) {

return <div className="button-reusable" style={{backgroundColor: color}} onClick={onClick}>
    {icon && <span className="material-icons">done</span>}
    {title}
</div>

}
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { getDevice, useDevice } from '../../hooks/device.hook';
import { request } from '../../services/http';
import Icon from '../reusable/Icon';
import RoundedButton from '../reusable/RoundedButton';
import { BACKEND, getResource } from './../../config';

export default function Footer() {
	const dispatch = useDispatch();
	const [scrolled, setScroll] = useState(false);
	const { width } = useDevice();
	const navigate = useNavigate()

	const [
		{
			ourProduction,
			readySolutions,
			aboutCompany,
			career,
			contacts,
			information,
			phone,
			email,
			contactUs,
			askQuestion,
			companyName,
			allRightsReserved,
		},
		setData,
	] = useState<{ [key: string]: string }>({});

	useEffect(() => {
		request(`${BACKEND}/api/database/headerfooter`, 'GET')
			.then(({ FOOTER }) => {
				setData(FOOTER);
			})
			.catch(({ message }) => console.log(message));
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', listenToScroll);
		return () => window.removeEventListener('scroll', listenToScroll);
	}, []);

	function listenToScroll() {
		document.body.scrollTop || document.documentElement.scrollTop === 0
			? setScroll(false)
			: setScroll(true);
	}

	return getDevice(width) == 'desktop' ? (
		<>
			<div className='footer'>
				<div className='footer-column'>
					<img src={getResource('logo_dark.png')} />
					<h3>{ourProduction}</h3>
					<a href='/retail'>Retail</a>
					<a href='/foodservice'>Foodservice</a>
				</div>
				<div className='footer-column'>
					<h3>{information}</h3>
					<a href='/retail'>{ourProduction}</a>
					<a href='/solutions'>{readySolutions}</a>
					<a href='/about'>{aboutCompany}</a>
					<a href='/career'>{career}</a>
					<a href='/contacts'>{contacts}</a>
				</div>
				<div className='footer-column'>
					<h3>{contacts}</h3>
					<h4>{phone}</h4>
					<Icon icon='fa fa-mobile'>
						<a href='tel:+7 (499) 956 20 46'>+7 (4742) 723 000</a>
					</Icon>
					{/* <a href='tel:+7 (499) 956 20 46'>Москва: <span>+7 (499) 956 20 46</span></a> */}
					<span></span>

					<h4>{email}</h4>
					<Icon icon='fa fa-envelope-o'>
						<a href='mailto:info@wefry.ru'>info@wefry.ru</a>
					</Icon>
					<Icon icon='fa fa-envelope-o'>
						<a href='mailto:sales@wefry.ru'>sales@wefry.ru</a>
					</Icon>
				</div>
				{/* <div className="footer-column"> */}
				{/* <h3>Мы в социальных сетях</h3> */}
				{/* <Icon icon="fa fa-instagram">
                        <a href='instagram'>lambweston_ru</a>
                    </Icon> */}
				{/* <Icon icon="fa fa-instagram">
                        <a href='instagram'>lwm_rus_team</a>
                    </Icon>
                    <Icon icon="fa fa-facebook">
                        <a href='facebook'>Lamb Weston Россия</a>
                    </Icon> */}
				{/* </div> */}
				{/* <div className='footer-column'>
					<h3>{contactUs}</h3>
					<RoundedButton
						title={askQuestion}
						onClick={() =>
							dispatch({ type: 'TOGGLE_MODAL', payload: 'feedback' })
						}
					/>
				</div> */}
			</div>
			<div className='footer__copyright'>
				{/* <span>Copyright © {new Date().getFullYear()}, Все права защищены.</span>
                <span><b>ООО Ви Фрай</b>, <b>ОГРН</b> 1124802000817, <b>ИНН</b> 4802024211, <b>КПП</b> 480201001</span><br/> */}
				<span>
					© {new Date().getFullYear()} {companyName} | {allRightsReserved}
				</span>
			</div>
		</>
	) : (
		<>
			<div className='footer-mobile'>
                <span className='footer-mobile__row'>
					{/* <span onClick={() => dispatch({ type: 'TOGGLE_MODAL', payload: 'feedback' })}>{askQuestion}</span> */}
					<span onClick={() => navigate('/about')}>{aboutCompany}</span>
				    <span onClick={() => navigate('/contacts')}>{contacts}</span>
                </span>
			</div>
			<div className='footer__copyright'>
				<span>
					© {new Date().getFullYear()} {companyName} | {allRightsReserved}
				</span>
			</div>
		</>
	);
}

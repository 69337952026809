import {useEffect, useRef, useState} from "react"

const scrollTimer = 3000

export function useOnScroll() {
    const [state, setState] = useState(false)
    const timerId = useRef<ReturnType<typeof setTimeout>>()

    const handleScroll = () => {
        setState(true)
        timerId.current && clearTimeout(timerId.current)
        timerId.current = setTimeout(() => setState(false), scrollTimer)
    }

    useEffect(() => {
        document.addEventListener('scroll', () => handleScroll())
        return document.removeEventListener('scroll', () => handleScroll())
    }, [])

    return state
}
import React, { Fragment, useEffect, useState } from "react";
import Curtain from "../components/reusable/Curtain";
import Loader from "../components/reusable/Loader";
import { BACKEND, getResource } from "../config";
import {useDevice} from "../hooks/device.hook";
import { request } from "../services/http";
import { Curtain as TCurtain } from "../types/controlTypes";

type fieldToDishBlock = {
    img: string
    label: string
    title: string
    description?: string
}

type State = {curtain: TCurtain, points: fieldToDishBlock[]}

export default function FieldToDish() {
    const [{points, curtain}, setData] = useState<State>({} as State)
    const {device} = useDevice()

    useEffect(
        () => {
            request(`${BACKEND}/api/database/field_to_dish`, 'GET')
                .then((data) => {
                   setData(data)
                })
                .catch(({message}) => console.log(message))
        },
        []
    )

    if (!points)
        return <Loader high/>

    return (
        <>
            <Curtain
                title={curtain.title}
                description={curtain.description}
                imgUrl={getResource(curtain.img)}/>
            {device == 'desktop'
                ? <div className="field-to-dish">
                    {points?.map(({img, label, title, description}, index) => index % 2
                        ? <Fragment key={title}>
                            <div className="field-to-dish__text">
                                <span className="field-to-dish__text-header">
                                    <img src={getResource(label)}/>
                                    <h2>{title}</h2>
                                </span>
                                <p>{description}</p>
                            </div>
                            <div className="field-to-dish__photo" style={{backgroundImage: `url(${getResource(img)})`}}/>
                        </Fragment>
                        : <Fragment key={title}>
                            <div className="field-to-dish__photo" style={{backgroundImage: `url(${getResource(img)})`}}/>
                            <div className="field-to-dish__text">
                                <span className="field-to-dish__text-header">
                                    <img src={getResource(label)}/>
                                    <h2>{title}</h2>
                                </span>
                                <p>{description}</p>
                            </div>
                        </Fragment>
                    )}
                </div>
                : <div className="field-to-dish">
                    {points?.map(({img, label, title, description}, index) =>
                        <Fragment key={index}>
                            <div className="field-to-dish__photo" style={{backgroundImage: `url(${getResource(img)})`}}/>
                            <div className="field-to-dish__text">
                                <span className="field-to-dish__text-header">
                                    <img src={getResource(label)}/>
                                    <h2 style={{...index == 1 && {fontSize: '0.9em'}}}>{title}</h2>
                                </span>
                                <p>{description}</p>
                            </div>
                        </Fragment>
                )}
                </div>
            }
        </>
    )
}

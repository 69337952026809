import React, { useEffect, useState } from 'react';
import Curtain from '../components/reusable/Curtain';
import { BACKEND, getResource } from '../config';
import ContactsCards from '../components/ContactsCards';
import { request } from '../services/http';
import Icon from '../components/reusable/Icon';
import { useDevice } from '../hooks/device.hook';

type Data = {
	curtain: { title: string; description: string };
	cards: string[][];
	phone: string;
	mail1: string;
	mail2: string;
} & Record<string, string>;

export default function About() {
	const [data, setData] = useState<Data>();
	const { device } = useDevice();

	useEffect(() => {
		request(`${BACKEND}/api/database/contacts`, 'GET')
			.then(data => {
				setData(data);
			})
			.catch(({ message }) => console.log(message));
	}, []);

	if (!data) return null;

	const {
		cards,
		curtain,
		mail1,
		mail2,
		phone,
		block1,
		block2,
		adress,
		company,
	} = data;

	return (
		<>
			<Curtain
				title={curtain.title}
				imgUrl={getResource(curtain.description)}
			/>
			<h1 className='t_grafit mt_big'>{block1}</h1>
			<ContactsCards cards={cards} />

			<div className='contacts-2cards'>
				<div
					className='contacts-2cards__first contacts-2cards__first_map'
					style={{
						backgroundImage: `url(${getResource('map.png')})`,
						backgroundSize: 'cover',
					}}
				>
					<span className='contacts-2cards__first_map-text'>
						<span style={{ display: 'flex', flexDirection: 'column' }}>
							<Icon icon='fa fa-map-marker'>
								<span style={{ color: 'white' }}>{adress}</span>
							</Icon>
							<br />
							<Icon icon='fa fa-mobile'>
								<a style={{ color: 'white' }} href={`tel:${phone}`}>
									{phone}
								</a>
							</Icon>
						</span>
						<h1 className='mt_big'>{company}</h1>
					</span>
				</div>
				<div className='contacts-2cards__second contacts-2cards__second_map'>
					<iframe
						src='https://yandex.ru/map-widget/v1/-/CCUfmYrrxD'
						height='100%'
						width='100%'
						frameBorder=''
					></iframe>
				</div>
			</div>
			<br />
			<div className='contacts-2cards'>
				{device == 'desktop' ? (
					<>
						<div className='contacts-2cards__first'>
							<span>
								<h2 className='t_grafit mt_big'>{block2}</h2>
								<br />
								<a className='contacts-cards__tel' href={`tel:${phone}`}>
									{phone}
								</a>
							</span>
							<img src={getResource('./icon_sun.svg')} />
						</div>
						<div className='contacts-2cards__second'>
							<div className='contacts-2cards__links'>
								<p className='t_grafit'>{block2}</p>
								<span className='contacts-cards__contact-row'>
									<img src={getResource('./icon_mail.svg')} />
									<a className='contacts-cards__mail' href={`mailto:${mail1}`}>
										{mail1}
									</a>
								</span>
								<br />
								<p className='t_grafit'>{block2}</p>
								<span className='contacts-cards__contact-row'>
									<img src={getResource('./icon_mail.svg')} />
									<a className='contacts-cards__mail' href={`mailto:${mail2}`}>
										{mail2}
									</a>
								</span>
							</div>
							<img src={getResource('./icon_speak.svg')} />
						</div>
					</>
				) : (
					<>
						<div className='contacts-2cards__first'>
							<span>
								<h2 className='t_grafit mt_ltl'>{block2}</h2>
                                <br/>
                                <span className='contacts-cards__contact-row'>
									<img src={getResource('./icon_tel.svg')} />
                                    <a className='contacts-cards__mail' href={`tel:${phone}`}>
									    {phone}
								    </a>
								</span>
                                <br/>
								<span className='contacts-cards__contact-row'>
									<img src={getResource('./icon_mail.svg')} />
									<a className='contacts-cards__mail' href={`mailto:${mail1}`}>
										{mail1}
									</a>
								</span>
                                <br/>
								<span className='contacts-cards__contact-row'>
									<img src={getResource('./icon_mail.svg')} />
									<a className='contacts-cards__mail' href={`mailto:${mail2}`}>
										{mail2}
									</a>
								</span>
							</span>
						</div>
					</>
				)}
			</div>
		</>
	);
}

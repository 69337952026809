import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { BACKEND, getIcon, getResource } from "../config"
import Curtain from "../components/reusable/Curtain"
import CareerSlider, { CareerSliderItemType } from "../components/CareerSlider"
import CareerCards from "../components/CareerCards"
import CareerDownloadLinks from "../components/CareerDownloadLinks"
import CareerVideos from "../components/CareerVideos"
import { request } from "../services/http"
import downloadFile from "../utils/downloadFile"
import {useDevice} from "../hooks/device.hook"

type Data = {
    curtain: {title: string, description: string}
    items: (CareerSliderItemType | CareerSliderItemType[])[],
    cards: string[][],
    downloadDocsLinks: string[],
    videos: string[]
} & Record<string, string>

export default function () {
    const [data, setData] = useState<Data>()
    const {width} = useDevice()
    const isMobile = width < 701

    useEffect(
        () => {
            request(`${BACKEND}/api/database/career`, 'GET')
                .then((data) => {
                   setData(data)
                })
                .catch(({message}) => console.log(message))
        },
        []
    )

    if (!data)
        return null

    const {
        block1, block2, block3, block4, block5,
        block6, block7, block8, block9,
        curtain, items, cards, downloadDocsLinks, videos, we, or} = data
    return (
        <>
            <Curtain title={curtain.title} imgUrl={getResource(curtain.description)}/>
            <h1 className="t_grafit t_mid mt_big t_center">
                {block1}
            </h1>
            <CareerSlider items={items} we={we}/>
            <div className="career-cards__header">{block4}</div>
            <CareerCards cards={cards}/>
            <h1 className="t_grafit t_mid mt_ltl t_center">
                {block5}
            </h1>
            <CareerDownloadLinks links={downloadDocsLinks}/>
            {/* <h1 className="t_grafit t_mid mt_big t_center">
                {block2}
            </h1> */}
            {/* <CareerVideos videos={videos}/> */}
            <div className="career-hh">
            <h1 className="t_grafit t_mid mt_ltl t_center">
                {block6}
            </h1>
            <div className="career-hh__buttons">
                <div
                    onClick={() => window.open('https://lipetsk.hh.ru/employer/2348830')}
                    className="career-hh__hh"
                >
                    {block7}
                </div>
                {!isMobile && <div className="career-hh__or">{or}</div>}
                <a href="mailto:cv@wefry.ru">
                    <div className="career-hh__mail">
                        {block8}
                    </div>
                </a>
                {!isMobile && <div className="career-hh__or">{or}</div>}
                <div
                    onClick={() => downloadFile(getResource('анкета.docx'))}
                    className="career-hh__button"
                >
                    {block9}
                </div>
            </div>
            </div>
            <h1 className="t_grafit t_mid mt_ltl t_center">
                {block3}
            </h1>
            <Contacts data={data}/>
        </>
    )
}


function Contacts ({data}: {data: Data}) {
    const mailRef = useRef<HTMLAnchorElement>(null)
    const instaRef = useRef<HTMLAnchorElement>(null)
    const {
        contacts1,
        contacts2,
        contacts3,
        contacts4,
        contacts5,
        contacts6,
        contacts7,
        contacts8
    } = data

    return (
        <div className="career-contacts">
            <div className="career-contacts__contact">
                <img src={getResource(contacts1)}/>
                <div>
                    <span>{contacts2}</span>
                    <a href={`tel: ${contacts3}`}>{contacts4}</a>
                    <a href={`tel: ${contacts5}`}>{contacts6}</a>
                </div>
            </div>
            <div className="career-contacts__contact" onClick={() => mailRef.current && mailRef.current.click()}>
                <img src={getResource(contacts7)}/>
                <div>
                    <a ref={mailRef} href={`mailto: ${contacts8}`}>{contacts8}</a>
                </div>
            </div>
            {/* <div className="career-contacts__contact" onClick={() => instaRef.current && instaRef.current.click()}>
                <img src={getIcon('insta.svg')}/>
                <div>
                    <a ref={instaRef} href="https://www.instagram.com/lwm_rus_team">@lwm_rus_team</a>
                </div>
            </div> */}
        </div>
    )
}
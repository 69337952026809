import React from "react";

export default function ({onChange, value = false, text}: {onChange: (value: boolean) => void, value: boolean, text?: string}) {
    return (
        <label className="custom-checkbox">
            <span className={`custom-checkbox__text${value ? '_active' : ''}`}>{text}</span>
            <input checked={value} onChange={(event) => onChange(event.target.checked)} type="checkbox"/>
            <span className="checkmark"/>
        </label>
    )
}
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import Curtain from "../reusable/Curtain"
import { getResource } from "../../config"

export default function HowToCook() {
    const {type} = useParams()
    useEffect(
        () => console.log(`product_type: ${type}`),
        [type]
    )
    return <>
    	<Curtain
			title={'Страница в разработке'}
			imgUrl={getResource('about_head_img.png')}
            imgClass='curtain-content-right how-to-cook__curtain-img'
		/>
        <div className="how-to-cook">
            <h1 className="t_terrakot mt_big">
                Страница в разработке
            </h1>
        </div>
    </>
}

import React from "react";
import { getResource } from "../config";

const colorAccordance = {
    twoColumn: {
        0: 'terrakot',
        1: 'greeen',
        2: 'blue',
        3: 'yellow',
        4: 'yellow',
        5: 'terrakot'
    },
    vertical: {
        0: 'yellow',
        1: 'green',
        2: 'green',
        3: 'terrakot',
        4: 'terrakot',
        5: 'yellow',
        6: 'yellow',
        7: 'green',
        8: 'green',
        9: 'terrakot',
        10: 'terrakot',
        11: 'yellow',
        12: 'yellow',
        13: 'green',
        14: 'green',
        15: 'terrakot',
        16: 'terrakot',
        17: 'yellow'
    },
    verticalOrder: {
        0: 'yellow',
        1: 'green',
        2: 'terrakot',
        3: 'yellow',
        4: 'green',
        5: 'terrakot',
        6: 'yellow',
        7: 'green',
        8: 'terrakot',
        9: 'yellow',
        10: 'green',
        11: 'terrakot',
    },
    sixColumn: {
        0: 'yellow',
        1: 'green',
        2: 'terrakot',
        3: 'yellow',
        4: 'green',
        5: 'terrakot',
        6: 'green',
        7: 'terrakot',
        8: 'yellow',
        9: 'green',
        10: 'terrakot',
        11: 'yellow',
        12: 'terrakot',
        13: 'yellow',
        14: 'green',
        15: 'terrakot',
        16: 'yellow',
        17: 'green',
        18: 'terrakot',
        19: 'yellow',
        20: 'green',
        21: 'terrakot',
        22: 'yellow',
        23: 'green',
        24: 'terrakot',
    }
}

export default function SliceListItem({text, index, direction}: {text: string, index: number, direction?: keyof typeof colorAccordance}) {
    return (
        <li className="slice-list__item">
            <img
                // style={{transform: `rotate(${index % 2 ? 170 : 190}deg)`}}
                src={getResource(`slice_${
                    direction
                        //@ts-ignore
                        ? colorAccordance[direction][index]
                        //@ts-ignore
                        : colorAccordance.vertical[index]
                }.svg`)}/>
            {text}
        </li>
    )
}
export default function downloadFile(url: string) {
    const downloadLink = Object.assign(
        document.createElement('a'),
        {
            href: url,
            download: url.split('/').at(-1),
        }
    );

    document.body.append(downloadLink);
    downloadLink.click();
    downloadLink.remove();
}

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TinitialState} from '../store'

export type TUser = {
  _id: string
  email?: string
  password?: string | number
  jwtToken?: string
  expire?: Date | string
}

export const useAuth = () => {
  const dispatch = useDispatch()
  const ud = useSelector((state: TinitialState) => state.user)
  const login = useCallback((ud: TUser) => {
    dispatch({
      type: 'LOGIN',
      payload: {
        user: ud,
      },
    })
  }, [])

  const logout = useCallback(() => {
    dispatch({ type: 'LOGOUT' })
  }, [])

  const reloginOnRefresh = useCallback(() => {
    if (ud.jwtToken && ud.expire) {
      if (new Date(ud.expire).getTime() < Date.now()) {
        logout()
        return console.log('Токен опоздал ' + ud.expire)
      } else if (new Date(ud.expire).getTime() > Date.now()) {
        ud && login(ud)
        return console.log('Токен действительный ' + ud.expire)
      }
    }
  }, [ud])

  return { login, logout, reloginOnRefresh }
}

import React from "react";

type Props = {
    title: string
    color?: string
    onClick: ()=>void
}

export default function({title, color, onClick}: Props) {
    return (
        <div onClick={onClick} className="rounded-button" style={{backgroundColor: color}}>
            {title}
        </div>
    )
}
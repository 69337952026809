import React from "react";
import downloadFile from "../utils/downloadFile";
import { getResource } from './../config';

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export default function ({links}: {links: string[]}) {
    return (
        <div className="career-links__container">
            <div className="career-links">
                {links.map(link =>
                    <div
                        key={link[0]}
                        onClick={() => downloadFile(getResource(link[0]))}
                    >
                        {link[1]}
                    </div>)}
            </div>
        </div>
    )
}
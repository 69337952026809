import React, { InputHTMLAttributes } from "react";

export default function Input({placeholder, onChange, name, value, ...props}: InputHTMLAttributes<HTMLInputElement>) {
    
    return (
        <input 
            placeholder={placeholder}
            value={value || ''} 
            onChange={onChange} 
            type='text' 
            name={name}
            {...props}
        />
    )
}
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BACKEND } from '../config';
import { request } from '../services/http';

const validateEmail = (email: string) => {
	return !!String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

type Type = { value: string; label: string };

type Data = {
	texts: string[];
	types: Type[];
	placeholders: string[];
};

export default function FeedBack({ open }: { open: boolean }) {
	const [data, setData] = useState<Record<string, string>>({ type: 'report' });
	const dispatch = useDispatch();

	const [{ texts, types, placeholders }, setTexts] = useState<Data>({
		texts: [],
		types: [],
		placeholders: [],
	});

	useEffect(() => {
		request(`${BACKEND}/api/database/feedback`, 'GET')
			.then(data => {
				setTexts(data);
			})
			.catch(({ message }) => console.log(message));
	}, []);

	function handleSetData(data: Record<string, string>) {
		setData(prev => ({ ...prev, ...data }));
	}

	return open ? (
		<>
			<div className='feed-back-modal'>
				<div className='feed-back-modal__content'>
					<div className='feed-back-modal__title'>
						<span className='feed-back-modal__title_head'>
							<h2>{texts[0]}</h2>
							<i
								className='fa fa-times'
								onClick={() =>
									dispatch({ type: 'TOGGLE_MODAL', payload: 'feedback' })
								}
							></i>
						</span>
						<hr />
						<p>
							<b>{texts[1]}</b>
							<br />
							{texts[2]}
							<br />
							{texts[3]}
						</p>
					</div>
					<div className='feed-back-modal__select'>
						<h3>{texts[4]}</h3>
						<select
							name='type'
							onChange={({ currentTarget }) =>
								handleSetData({ [currentTarget.name]: currentTarget.value })
							}
							value={data.type}
						>
							{types.map(({ value, label }) => (
								<option value={label}>{value}</option>
							))}
						</select>
					</div>
					<div className='feed-back-modal__description'>
						<h3>{texts[5]}</h3>
						<textarea
							placeholder={placeholders[0]}
							value={data.description}
							name='description'
							onChange={({ currentTarget }) =>
								handleSetData({ [currentTarget.name]: currentTarget.value })
							}
						/>
					</div>
					<div className='feed-back-modal__response'>
						<h3>
							{texts[6]}
							<br />
							{texts[7]}
						</h3>
						<input
							value={data.email}
							type={'email'}
							placeholder={placeholders[1]}
							name='email'
							onChange={({ currentTarget }) =>
								handleSetData({ [currentTarget.name]: currentTarget.value })
							}
						/>
					</div>
					<hr />
					<div className='feed-back-modal__button'>
						<button
							disabled={
								!(
									data.type &&
									data.description &&
									(!data.email || validateEmail(data.email))
								)
							}
							onClick={() => console.log(JSON.stringify(data))}
						>
							{texts[8]}
						</button>
					</div>
				</div>
			</div>
			<div
				onClick={() => dispatch({ type: 'TOGGLE_MODAL', payload: 'feedback' })}
				className='feed-back-modal__dimmer'
				onScroll={event => {
					event.preventDefault();
					event.stopPropagation();
				}}
			></div>
		</>
	) : null;
}

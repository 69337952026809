import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TinitialState} from "../store";

const popupTime = 3000

export function usePopup() {
    const ref = useRef<ReturnType<typeof setTimeout>>()
    const {popup} = useSelector((state: TinitialState) => state)
    const dispatch = useDispatch()
    const togglePopup = (message: string) => dispatch({type: 'TOGGLE_POPUP', payload: message})
    const hidePopup = () => togglePopup('')

    useEffect(() => {
        if (ref.current)
            clearTimeout(ref.current)

        if (popup)
            ref.current = setTimeout(hidePopup, popupTime)
    }, [popup])


    return {
        togglePopup,
        hidePopup,
        popup
    }
}
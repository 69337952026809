import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import downloadFile from "../utils/downloadFile"
import { getOther } from "../config"
import PageLoader from "./reusable/PageLoader"
import React from "react"

export default function System() {
    const {type} = useParams()
    const navigate = useNavigate()

    useEffect(
        () => {
            if (type === 'vpn')
                downloadFile(getOther('vpn.msi'))

            return navigate('/')
        },
        [type]
    )

    return <PageLoader/>
}
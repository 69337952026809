import {useState, useCallback} from 'react'

type HTTP = (
    url: string,
    method: 'GET' | 'POST',
    body?: object | null | string,
    headers?: Record<string, string>,
    formData?: boolean
) => Promise<{message: string, data: unknown}>

type Error = {message: string} | null

export const useHttp = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>(null)

  const request = useCallback<HTTP>(async(url, method = 'GET', body = null, headers = {}, formData = false) => {
    setLoading(true)
    try {
      if (body) {
        if (formData) {
          const formData = new FormData()
          Object.entries(body).forEach(([key, value]) => formData.append(key, value))
          body = formData as unknown as string
        } else {
          body = JSON.stringify(body)
          headers['Content-Type'] = 'application/json'
        }
      }
      const response = await fetch(url, {
        method, 
        body, 
        headers
      })
      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Что-то пошло не так')
      }

      setLoading(false)

      return data
    } catch (e) {
      setLoading(false)
      // setError(e.message)
      throw e
    }
  }, [])

  const clearError = useCallback(() => setError(null), [])

  return { loading, request, error, clearError }
}